// Tour
.v-tour {
  .v-step {
    filter: none !important;
    color: $gray-900 !important;
    box-shadow: 0 1px 10px rgba(black, .15);
    background-color: $gray-100 !important;

    .v-step__buttons .v-step__button {
      @extend .btn, .btn-outline-secondary, .btn-sm;
      height: auto !important;
      font-size: .8rem !important;
    }

    .v-step__arrow {
      border-color: $gray-100 !important;
    }
    &[x-placement^='bottom'] .v-step__arrow {
      border-top-color: transparent !important;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
    }
    &[x-placement^='top'] .v-step__arrow {
      border-bottom-color: transparent !important;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
    }
    &[x-placement^='right'] .v-step__arrow {
      border-top-color: transparent !important;
      border-left-color: transparent !important;
      border-bottom-color: transparent !important;
    }
    &[x-placement^='left'] .v-step__arrow {
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
    }
  }
}
.tour-active {
  box-shadow: 0 3px 40px rgba(#f46548, 1);
  //border: 1px solid #f46548;
}

// Page specific

.modules .col-xl-4,
.modules .col-xl-6 {
  margin-bottom: 30px;

  .sub-item {
    height: 100%;
    padding-left: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: no-repeat 10px center;
    background-size: 55px;
  }
}

.card-body.log {
  .sub-item {
    background-size: 55px;
    padding-left: 90px;
  }
}



// ContentAssist Media
.dropzone {
  width: 100%;
  min-height: 200px;
  cursor: pointer;
  position: relative;
  background-color: $gray-800;
  transition: .3s;
  border-color: $gray-400 !important;

  &.active {
    background-color: $gray-700;
    border-color: $primary !important;
  }

  &:hover {
    background: $gray-600;
  }

  #fileChooser {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 150px;
    cursor: pointer;
  }
}

#ca_mediaList {
  .card-img-overlay {
    background-color: rgba(black, .5);
    transition: .3s ease-in;

    &:hover {
      background-color: transparent;

      .btn.btn-secondary:not(.media-checker) {
        background-color: $danger !important;
      }
    }

    //.mediaChooser{
    //  font-size:
    //}
  }
}

.log .sub-item.error:before {
  content: "\E002";
}

.log .sub-item.launch:before {
  content: "\E895";
}

.log .sub-item.limit:before {
  content: "\E002";
}

.log .sub-item.settings:before {
  content: "\E8B8";
}

.log .sub-item.radiostation:before {
  content: "\E03E";
}

.log .sub-item.licence:before {
  content: "\E0DA";
}

.log .sub-item.user:before {
  content: "\E7FD"
}

.log .sub-item.module:before {
  content: "\E896";
}

.orders .sub-item:before {
  content: '\E8CC';
}

.versionhistorylist .sub-item {
  padding-left: 25px;
}
