.btn[data-target="#npclipinfo"] {
  &.collapsed {
    span{
      display: none;
    }
    span.collapse-content {
      display: inline;
    }
  }
  &:not(.collapsed){
    span.collapse-content{
      display: none;
    }
  }
}


.np-track {
  padding: 20px;
  background-color: $gray-700;
  cursor: pointer;
  transition: .2s;

  &:hover {
    background-color: $gray-800;
  }
}

.playercontainer {
  position: relative;
}

#playpause {
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: url('/img/play.png');
  background-size: 50%;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
}