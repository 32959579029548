@import "moduleshop";
@import "modules/streamassist";
@import "modules/npclipassist";


main#scheduleassist {
  .table-bordered {
    td {
      border-color: $gray-600 !important;
    }
  }
}