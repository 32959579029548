@import "../global";
@import '../../../../node_modules/@fullcalendar/core/main.css';
@import '../../../../node_modules/@fullcalendar/daygrid/main.css';
@import '../../../../node_modules/@fullcalendar/timegrid/main.css';
@import '../../../../node_modules/@fullcalendar/bootstrap/main.css';
//'~
// MAIN VIEW

main.col-md-9 {
  min-height: calc(100vh - 30px);
  padding: 56px 15px 3rem 15px;
  @include media-breakpoint-down(sm) {
    padding: 4.5rem 0px;
  }

  > .container {
    padding-top: 15px;
  }

  .modal {
    @include media-breakpoint-up(md) {
      @include make-col(9);
    }
    @include media-breakpoint-up(lg) {
      @include make-col(10);
    }
    @include media-breakpoint-down(md) {
      .modal-dialog {
        margin-top: 4rem;
      }
    }
    width: 100%;
    left: initial;
  }

  .modal-backdrop {
    z-index: 100;
  }

  .dropdown-menu[x-placement='top-start'] {
    margin-top: 4rem;
  }

  //.modal .dropdown-menu[x-placement='top-start']{
  //  max-height: calc(100vh - 5rem);
  //  overflow-y: scroll;
  //}

  .loading-top {
    position: fixed;
    top: 0;
    margin-left: -15px;
    margin-right: -15px;
    width: calc(100% + 30px);
    min-height: 3.5rem;
    background-color: $dark;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1054;
    box-shadow: 0 .1rem .7rem rgba(black, .1);
    margin-bottom: 2rem;
    transform: translateY(0);
    transition: .2s ease-in;
    overflow: hidden;

    &.loading, &.error, &.success {
      transform: translateY(3.5rem);
    }

    &.loading-full {
      //width: calc(100vw + 50%);
      //margin-left: calc(-50%);
      //z-index: 10000;
    }

    &:before {
      position: absolute;
      top: 0;
      left: 0%;
      transform: translateX(-50%);
      content: '';
      @include gradient();
      width: 200%;
      height: 4px;
      @keyframes gradientmove {
        0% {
          opacity: .5;
          transform: translateX(-50%);
        }
        100% {
          opacity: 1;
          transform: translateX(0%);
        }
      }
      animation: gradientmove 1s alternate infinite both;
    }

    &.error:before {
      background: $danger none;
    }

    &.success:before {
      background: $success none;
    }
  }

}

// card corrections vra

.card max.circle {
  width: 35px;
  height: 35px;
  top: -15px;
  right: -15px;
  color: white;
  font-weight: normal;
  @include z-depth-2;
}

.circlelist max.circle:nth-child(2),
.circlelist a:nth-child(2) max.circle {
  top: 30px;
}

.circle {
  display: block;
  overflow: hidden;
  position: absolute;
  border-radius: 50%;
}

.circle span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  line-height: 1;
  text-align: center;
}

.alert:first-letter {
  text-transform: capitalize;
}

.block-list {
  margin: -15px !important;
}

.block-list .card {
  margin: 15px 0;
  //margin-top: 30px;
}

.block-list .card.col-sm-4 {
}

.block-list .card.col-sm-6 {
}

/*
Loading spinners
 */

#loading {
  width: 100%;
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
  align-items: center;
  justify-content: center;
  @include gradient(.8);
  display: none;
  opacity: 0;

  &.active {
    @keyframes showup {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    display: flex;
    animation: showup .3s ease-in forwards;
  }

  &:before {
    border: 6px solid transparent;
    border-radius: 50%;
    border-top: 6px solid #ffffff;
    width: 60px;
    height: 60px;
    -webkit-animation: spin .8s linear infinite;
    /* Safari */
    animation: spin .8s linear infinite;
    content: "";
  }
}

i.loading-inline {
  opacity: 0;
  display: none;

  &.active {
    display: inline-flex;
    opacity: 1;
  }

  &:before {
    display: inline-flex;
    border: 2px solid transparent;
    border-radius: 50%;
    border-top: 3px solid #ffffff;
    width: 17px;
    height: 17px;
    -webkit-animation: spin .8s linear infinite;
    /* Safari */
    animation: spin .8s linear infinite;
    content: "";
  }

  &.black:before {
    border-top: 3px solid #181818;
  }

  &.big:before {
    width: 30px;
    height: 30px;
  }

  &.slow:before {
    -webkit-animation: spin 1.2s linear infinite;
    /* Safari */
    animation: spin 1.2s linear infinite;
  }
}

/* Safari */

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/**


HAVE TO MOVE THISSS

 */
.dashboard {
  min-height: 25vh;
  margin-top: -2.5rem;

  .grad#userhello {
    margin-left: -15px;
    margin-right: -15px;
    padding: 6rem 15px calc(5rem + 10vh);
    //@include media-breakpoint-down(md) {
    //  h1.display-3 {
    //    font-size: 3rem;
    //    //&::first-letter {
    //    //  margin-right: 100%;
    //    //  font-size: 4rem;
    //    //  line-height: 200%;
    //    //  padding-bottom: 1rem;
    //    //}
    //  }
    .col.d-flex > div > p.text-uppercase {
      color: $gray-300;
      opacity: .6;
      font-weight: 500;
    }

  }


  .container.content {
    margin-top: -10vh;
  }
}

.main-designer {


  #stickytop {
    @include media-breakpoint-down(lg) {
      & {
        top: 0px;
      }
    }
    @include media-breakpoint-up(lg) {
      & {
        position: sticky;
        top: 0;
        z-index: 1020;
      }
    }
  }
}

.trigger-drop .dropdown-menu {
  max-height: calc(100vh - 80px);
  overflow-y: scroll;
  overflow-x: hidden;
}


@import "items";
@import "nav";
@import "pages";
@import "docs";
@import "../tiers";
@import "../landing_page/modules";


