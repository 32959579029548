@import "dashboard";
@import "account";
@import "admin";
@import "server_card";
@import "modules";
@import "auth/login";

body {
}

#helpdesk {
  .card {
    table {
      td {
        padding: .25rem 0;
        &:first-child {
          font-weight: bold;
          padding-right: 1rem;
        }
      }
    }
  }
  .col-sm-8 {
    height: 100vh;
    iframe {
      height: 100%;
    }
  }
}

