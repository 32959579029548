body#log_in {
  @include media-breakpoint-up(md) {
    background: url("../../images/bg-20.jpg") no-repeat local center;
    background-size: cover;
  }

  main .container-fluid {

    .row, .col-sm-5 {
      height: 100%;
    }

    #control_panel_logo {
      display: none;
    }

    @include media-breakpoint-up(md) {
      .col-xl-6 {
        #control_panel_logo {
          display: block;
          position: absolute;
          width: 60%;
          top: 50%;
          transform: translate(-50%, -50%);
          left: 50%;
          -moz-user-select: none;
          -webkit-user-select: none;
          user-select: none;
          pointer-events: none;
        }

        //&:after{
        //  content: '';
        //  position: absolute;
        //  width: 100%;
        //  height: 100%;
        //  background: url("../../images/vra_control_panel.svg") center center no-repeat;
        //  background-size: 90% auto;
        //  left: 0;
        //  @include media-breakpoint-up(lg){
        //    background-size: auto 100px;
        //  }
        //}
      }
    }

    .col-xl-6 .card {
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
    }
  }
}