.moduleshop .card-columns {
    @include media-breakpoint-only(lg) {
        column-count: 3;
    }
    @include media-breakpoint-only(xl) {
        column-count: 4;
    }
}
@media (min-width: 576px) {
    .moduleshop .card-columns {
        column-gap: 2rem!important;
    }
    .moduleshop .card-columns .card{
        margin-bottom: 2rem!important;
        transition: .2s ease-in-out;
        cursor: pointer;
    }
    .moduleshop .card-columns .card:hover{
        transform: scale(1.03);
    }
    
}