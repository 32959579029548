.sub-items {
  margin-top: 10px;
}

.account .sub-item {
  background-color: rgba(255, 255, 255, .99);
}

.main .sub-item {
  background-color: #ecf0f1;
}

.sub-item {
  position: relative;
  padding: 25px;
  border-radius: 5px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, .15);
  margin-bottom: 20px;
  background-color: $gray-700;
  &:last-child{
    margin-bottom: 0;
  }
  & span {
    font-size: 15px;
  }
  & h6 {
    font-weight: bold;
    font-size: 23px;
    line-height: 125%;
    margin: 0;
    word-wrap: break-word;
  }
  &.extra {
    padding-left: 25px;
    & h6 {
      font-size: 18px;
      text-align: center;
    }
  }
  & max {
    color: white;
    font-size: 25px;
    font-weight: normal;
    position: absolute;
    right: -15px;
    top: -15px;
    width: 35px;
    height: 35px;
    box-shadow: 0 1px 10px rgba(0, 0, 0, .2);
    &.second {
      left: -15px;
      right: inherit;
    }
    & i {
      font-size: 18px;
    }
  }
}

.list-group {
  .list-group-item {
    background-repeat: no-repeat;
    &.with-tumb,&.with-tumb-icon{
      padding: 20px 20px 20px 85px;
      background-position: 25px center;
      background-size: 35px;
    }
    &.with-tumb-icon{
      &:before{
        position: absolute;
        left: 25px;
        top: 50%;
        font-family: "Material Icons";
        font-size: 45px;
        transform: translateY(-50%);
        line-height: 50px;
      }
    }
  }
}


