#app.docs {
  .sidebar {
    margin-top: 3.5rem;

    a {
      text-decoration: none;

    }
  }

  .documentation {
    color: $dark;

    h4 {
      margin: 1rem 0 .5rem;
    }

  }
}
