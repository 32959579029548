#radiostationView .list-group-item {
  cursor: pointer;
}

#userView .modal-title b.text-dark {
  cursor: pointer;
  text-decoration: underline;
}

@media (min-width: 992px) {
  #radiostationView .modal-lg, #createRadiostation .modal-lg {
    max-width: 992px !important;
  }
}

#createRadiostation .breadcrumb-item + .breadcrumb-item::before {
  content: "\E315";
  font-family: "Material Icons";
  vertical-align: middle;
  margin-top: -4px;
  margin-bottom: -2px;
}

#radiostationView {
  .servers {

  }

  .modules .circlelist max.circle {
    cursor: pointer;
  }

}

//#RadiostationModal.modal {
//  .modal-dialog {
//    max-width: 100%;
//  }
//}
