.video-js {
  position: absolute !important;
  top: 0;
  width: 100%;
  height: 100%;
}

.stream-overlay h4 {
  padding: 25px;
  text-align: center;
}

#toggle_teststream {
  position: absolute;
  z-index: 80;
  right: 0;
  top: 0;
}

.stream-overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.95);

  &.hidden {
    display: none !important;
    opacity: 0;
  }
}


.controls .btn.btn-trigger {
  box-shadow: 0 2px 10px rgba($color: black, $alpha: .15);
  padding: 25px 10px;

  & .badge:before {
    font-family: "Material Icons";
    font-size: 30px;
    display: block;
    margin-bottom: 10px;
  }

  &.scene .badge::before {
    content: '\E883';
  }

  &.source .badge::before {
    content: '\E890';
  }

  &:hover {
    box-shadow: 0 5px 20px rgba($color: black, $alpha: .15);
  }

  & h5 {
    white-space: pre-wrap;
  }
}

.controls .badge {
  padding: 10px;
  text-transform: capitalize;
}

.channels {
  .list-group-item {
    &:before {
      content: '\E8BF';
    }

    &.live {
      &:before {
        color: darkred;
      }
    }
    max{
      cursor: pointer;
    }
  }
}