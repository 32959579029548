.servers .sub-item {
  background-size: 55px;
  transition: .3s ease-in;
  cursor: pointer;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-size: 55px;
  .check-server {
    i.material-icons::before {
      content: '\e836';
      transition: .3s ease-in;
    }
  }
  &:hover {
    background-color: $gray-600;
  }
  &:hover .check-server i.material-icons, &.active .check-server i.material-icons{
    &::before{
      content: '\e837';
    }
  }
}
.computers .sub-item {

  background: url("https://visualradioassist.nl/img/modules/computer.png") no-repeat 15px center;
}