
.server {
  .card {
    margin-bottom: 0;
  }
  .badge {
    //white-space: pre-wrap;
  }
  .server_status, .server_triggeritem {
    //height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    min-height: 160px;
    h2{
      margin-bottom: 0;
    }
    p{
      margin-bottom: .2rem;
    }
    &.bg-dark, &.bg-danger{
      color: $light;
    }
    .btn {
      margin-bottom: -31px;
      padding: .6rem;
      width: 100%;
    }
  }
  .server_status_refresh{
    @keyframes loading_refresh {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    cursor: pointer;
    transition: .4s ease-in;

    &:hover{
      transform: rotate(480deg);
    }
    &.loading{
      animation: loading_refresh .8s infinite linear;
    }
  }
  .server_modules{
    .card-body{
      @include media-breakpoint-down("sm"){
        padding: 0;
        h2{
          padding: 20px 20px 0;
        }
      }
    }
  }
}