//NAVIGATION

nav {
  .h4 {
    margin-bottom: 0;
  }

  &.navbar {
    z-index: 1060;
    box-shadow: 0 .1rem .7rem rgba(black, .1);
    max-height: 100vh;
    @include media-breakpoint-down(sm) {
      //overflow-y: scroll;
      & .navbar-nav > .nav-item {
        padding-top: .5rem;
        padding-bottom: .5rem;
      }
    }

    @at-root #app.docs & .navbar-toggler:first-child {
      position: relative;
      label {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }

    .navbar-brand {
      position: absolute;
      left: 0;
      top: 0;
      height: calc(56px * 1.3);
      @include media-breakpoint-down(md) {
        max-width: calc(100vw - 56px);
        @at-root #app.docs & {
          left: 65px;
          max-width: calc(100vw - 140px);
        }
      }
      margin: 0;
      padding: 0;

      > svg, > img {
        height: 100%;
        @include media-breakpoint-down(md) {
          width: 100%;
        }
        user-select: none;
        @keyframes flyIn {
          0% {
            opacity: 0;
            transform: translateX(-50%);
          }
          100% {
            opacity: 1;
            transform: translateX(0);
          }
        }

        #control_panel_block {
          opacity: 0;
          transform: translateX(-50%);
          animation: flyIn .5s ease-in;
          animation-fill-mode: forwards;
        }

        .cls-1 {
          isolation: isolate;
        }

        .cls-2, .cls-5 {
          opacity: 0;
        }

        .cls-3 {
          opacity: 0.23;
        }

        .cls-3, .cls-7 {
          mix-blend-mode: multiply;
        }

        .cls-4 {
          fill: #2b2b2b;
        }

        .cls-5 {
          fill: url(#vraGRAD);
        }

        .cls-6 {
          fill: #f2f2f2;
        }

        .cls-7 {
          opacity: 0.16;
        }

        .cls-8 {
          fill: url(#Naamloos_verloop_5);
        }
      }

      //font-size: 1rem;
      //line-height: 1;
      //
      //.h4 {
      //  line-height: 1;
      //
      //  .material-icons {
      //    margin-top: -.15rem;
      //    margin-bottom: 0;
      //  }
      //}
    }

    .navbar-collapse {
      li.nav-item {
        white-space: nowrap;

        #onairfocus {
          @keyframes dissapear {
            0% {
              margin-right: 0;
              opacity: 1;
            }
            100% {
              margin-right: -61px;
              opacity: 0;
            }
          }
          animation: .3s 2s dissapear;
          animation-fill-mode: forwards;
        }
      }
    }

    @include media-breakpoint-down(md) {
      #navbarContent {
        overflow: auto;
        max-height: 100vh;
      }
    }
  }

  &.sidebar-sticky {
    &:not(.sidebar-holder) {
      z-index: 1055;
      position: fixed;
      left: 0;
      top: 0;
      bottom: 0;
      box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
      padding: 3.5rem 0 0;
      height: 100vh;
      overflow: hidden auto;
    }

    &.sidebar-holder {
      padding-top: 1rem;
      margin-left: -1rem;
      margin-right: -1rem;
      width: calc(100% + 2rem);
    }

    a.account-block {
      background-color: $gray-700;
      transition: .2s ease-in-out;

      &:hover {
        //background-color: $gray-200;
        text-decoration: none;

        h5.text-muted {
          @keyframes arrow_move {
            0% {
              transform: translateX(0%);
            }
            100% {
              transform: translateX(30%);
            }
          }
          animation: arrow_move .5s ease-in-out alternate infinite;
        }
      }
    }

    .menu-wrapper {
      i.material-icons {
        width: 1.5rem;
        text-align: center;
        margin-right: 1rem;
      }

      ul.nav {
        .nav-link {
          color: $gray-300;
          margin-left: -1rem;
          margin-right: -1rem;
          padding: .75rem .8rem;
          transition: .2s ease-in;
          //text-transform: none;
          &:hover, &.active {
            color: $gray-100;
            background-color: $gray-900;
          }

          &.active {
            font-weight: 500;
            color: $primary;

            i.material-icons {
            }
          }
        }

        li.nav-item {
          ul {
            display: none;

            li.nav-item {
              list-style: none;
              //padding-left: 2.5rem;
              font-size: .9rem;

              i.material-icons {
                width: 1rem;
                margin-right: .25rem;
              }

              .nav-link {
                padding-top: .3rem;
                padding-bottom: .3rem;
              }
            }
          }

          &.active ul {
            display: block;
          }
        }
      }
    }
  }

}
